<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <section class="study_myinfo_list_area" style="margin-top:-16px;" >
            <div style="width:90%;margin:auto;">
              <section class="study_main_box error-403">
                 <p class="mb-2">
                    403
                 </p>
                 <p class="error-code">
                    Permission Denied
                 </p>
                 <img src="/image/common/page-error.png" style="margin-top: 15px;">
                 <p>
                    You don't have permission to access this resource.
                 </p>
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'home' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'Error403',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  }
}
</script>

<style scoped>

</style>
